import { CardItem } from '@/components/v3/molecules/custom-sliders/slider-with-navigation/slider-with-navigation'
import { Article } from '@/types/Article'
import { format } from 'date-fns'

export function mapArticleToCardProps(
  articles?: Article[]
): CardItem[] | undefined {
  return articles?.map((article) => ({
    id: article.id,
    title: article.title,
    path: article.path,
    date: format(article?.published_date || '', 'dd.MM.yyyy'),
    category:
      article.article_subcategories?.name || article.article_categories?.name,
    location: '',
    imageUrl: article.hero_image_ref.url!,
    imageAlt: article.hero_image_ref.alt!,
  }))
}
