import { API_URL } from '@/constants'
import { ApiHandlerResult } from '..'
import { BaseApi } from '../base'
import { ArticleSort, ArticleTemplate } from '@/types/api/article'
import { RequestInit } from 'next/dist/server/web/spec-extension/request'
import { Fetcher } from '@/api/fetcher'
import { Article } from '@/types/Article'
import { PaginationResponse } from '@/types/api/pagination-response'
import {
  ArticleCategory,
  ArticleCategoryPageData,
  ArticleCategoryPageDataType,
  ArticleSubcategory,
} from '@/types/api/article-category'
import { PodcastGroup } from '@/types/Podcast'

type GetArticleCategoryPageType = {
  slug: string
  type: ArticleCategoryPageDataType
}

type GetArticlesOptionsType = {
  page?: number
  pageSize?: number
  tag?: (string | number)[] | string | number
  sort?: ArticleSort
  trending?: boolean
  search?: string
  type?: ArticleTemplate
  title?: string
  category?: string | string[]
  subcategory?: string | string[]
  withBannerImage?: boolean
  excludedIds?: number[]
}

export class ArticleApi extends BaseApi {
  constructor(fetcher: Fetcher) {
    super(fetcher)
  }

  async getCategories(
    options?: RequestInit
  ): ApiHandlerResult<ArticleCategory[]> {
    try {
      const res = await this.fetcher.get(API_URL.ARTICLES.CATEGORIES, {
        ...options,
      })

      return await this.parseBodyOrThrow(res)
    } catch (err: any) {
      return [err, null]
    }
  }

  async getCategoryPageData(
    { slug, type }: GetArticleCategoryPageType,
    options?: RequestInit
  ): ApiHandlerResult<ArticleCategoryPageData> {
    try {
      const res = await this.fetcher.get(
        `${API_URL.ARTICLES.CATEGORY_PAGE_DATA}/${slug}`,
        {
          params: { type },
          ...options,
        }
      )

      return await this.parseBodyOrThrow(res)
    } catch (err: any) {
      return [err, null]
    }
  }

  async getSubcategories(
    params?: { parentSlug?: string } | null,
    options?: RequestInit
  ): ApiHandlerResult<ArticleSubcategory[]> {
    try {
      const res = await this.fetcher.get(API_URL.ARTICLES.SUBCATEGORIES, {
        ...options,
        params: {
          parent_slug: params?.parentSlug,
        },
      })

      return await this.parseBodyOrThrow(res)
    } catch (err: any) {
      return [err, null]
    }
  }

  async getById(id: string, options?: RequestInit): ApiHandlerResult<Article> {
    try {
      const res = await this.fetcher.get(
        `${API_URL.ARTICLES.GET_BY_ID}/${id}`,
        {
          ...options,
        }
      )

      return await this.parseBodyOrThrow(res)
    } catch (err: any) {
      return [err, null]
    }
  }

  async getByPath(
    params: {
      path: string
    },
    options?: RequestInit
  ): ApiHandlerResult<Article> {
    const { path, ...restParams } = params
    try {
      const res = await this.fetcher.get(
        `${API_URL.ARTICLES.PATH}/${encodeURIComponent(path)}`,
        {
          params: restParams,
          ...options,
        }
      )

      return await this.parseBodyOrThrow(res)
    } catch (err: any) {
      return [err, null]
    }
  }

  async search(
    params?: GetArticlesOptionsType,
    options?: RequestInit
  ): ApiHandlerResult<PaginationResponse<Article>> {
    try {
      const res = await this.fetcher.get(API_URL.ARTICLES.SEARCH, {
        params,
        ...options,
      })

      return await this.parseBodyOrThrow(res)
    } catch (err: any) {
      return [err, null]
    }
  }

  async getPodcastList(
    options?: RequestInit
  ): ApiHandlerResult<PodcastGroup[]> {
    try {
      const res = await this.fetcher.get(API_URL.ARTICLES.PODCAST, {
        ...options,
      })

      return await this.parseBodyOrThrow(res)
    } catch (err: any) {
      return [err, null]
    }
  }
}
