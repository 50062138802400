'use client'

import { Slider } from '@/components/v2/atoms/slider/slider'
import Card, { CardProps, CardType } from '@/components/v3/atoms/card/card'
import s from './slider-with-navigation.module.css'
import { ArrowRight } from '@/components/svg/arrow-right/arrow-right'
import cx from 'classnames'
import React from 'react'
import { KeenSliderOptions } from 'keen-slider'
import { SupplierLocation } from '@/types/Supplier'

interface Props {
  onItemClick?: () => void
  items: CardItem[]
  cardType: CardType
  navigationPosition?: 'right' | 'left'
  title: React.ReactNode
  keenSliderOptions?: KeenSliderOptions
  fullWidth?: boolean
  itemProps?: Partial<CardProps>
  topWrapperClassName?: string
  slidesWrapperClassName?: string
  sliderContainerClassName?: string
  sliderControlsClassName?: string
  sliderArrowClassName?: string
}

export type CardItem = {
  id: number | string
  title: string
  date?: string
  imageUrl?: string
  imageAlt?: string
  category?: string
  locations?: SupplierLocation[]
  path: string
}

export const SliderWithNavigation = ({
  onItemClick,
  items,
  cardType,
  navigationPosition = 'right',
  title,
  keenSliderOptions,
  fullWidth,
  itemProps,
  topWrapperClassName,
  slidesWrapperClassName,
  sliderControlsClassName,
  sliderArrowClassName,
}: Props) => {
  if (!items?.length) {
    return null
  }

  const primaryColor = itemProps?.primaryColor || '#fff'

  return (
    <Slider
      ignoreDefaultBreakpoints
      className={slidesWrapperClassName}
      options={{
        initial: 0,
        slides: {
          spacing: 24,
          perView: 'auto',
        },
        breakpoints: {},
        ...keenSliderOptions,
      }}
      renderNavigation={(slideLeft, slideRight) => {
        const navigationVisible = items.length > 3
        return (
          <div
            className={cx(s.topWrapper, topWrapperClassName, {
              [s.navigationToLeft]: navigationPosition === 'left',
              [s.fullWidth]: fullWidth,
            })}
          >
            {title}
            {navigationVisible && (
              <div className={cx(s.sliderControls, sliderControlsClassName)}>
                <button
                  onClick={slideLeft}
                  className={cx(s.sliderArrow, sliderArrowClassName)}
                >
                  <ArrowRight
                    iconColor={primaryColor}
                    svgProps={{
                      style: {
                        transform: 'rotate(180deg)',
                      },
                    }}
                  />
                </button>
                <button
                  onClick={slideRight}
                  className={cx(s.sliderArrow, sliderArrowClassName)}
                >
                  <ArrowRight iconColor={primaryColor} />
                </button>
              </div>
            )}
          </div>
        )
      }}
    >
      {items?.map((item) => {
        return (
          <div key={`${item.id}-nav-slider`} onClick={() => onItemClick?.()}>
            <Card
              primaryColor={primaryColor}
              reverseVertical
              size="Small"
              adjustToSlider
              itemId={item.id!}
              header={item.title}
              path={item.path}
              date={item.date}
              category={item?.category || 'Category'}
              locations={item.locations}
              type={cardType}
              imageUrl={item.imageUrl!}
              imageAlt={item.imageAlt!}
              {...itemProps}
            />
          </div>
        )
      })}
    </Slider>
  )
}
