import qs from 'qs'

export type FetcherOptions = RequestInit & {
  params?: unknown
}

export class Fetcher {
  public baseUrl: string
  protected defaultHeaders = {
    'Content-Type': 'application/json',
  }

  protected nextOptions: NextFetchRequestConfig = {}

  constructor(baseUrl: string, nextOptions?: NextFetchRequestConfig) {
    this.baseUrl = baseUrl
    this.nextOptions = { ...nextOptions }
  }

  protected buildUrl = (path: string, params?: unknown) => {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
      arrayFormat: 'repeat',
    })
    return this.baseUrl + path + queryString
  }

  protected requestHandlerFactory(method: string) {
    return async (path: string, init?: FetcherOptions) => {
      const url = this.buildUrl(path, init?.params)
      const { headers, ...restInit } = init || {}

      return fetch(url, {
        method,
        credentials: 'include',
        headers: { ...this.defaultHeaders, ...headers },
        next: this.nextOptions,
        ...restInit,
      })
    }
  }

  public get = this.requestHandlerFactory('GET')
  public post = this.requestHandlerFactory('POST')
  public put = this.requestHandlerFactory('PUT')
  public delete = this.requestHandlerFactory('DELETE')
}
