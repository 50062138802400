import { BaseApi } from '../base'
import { Fetcher } from '@/api/fetcher'
import { PaginationResponse } from '@/types/api/pagination-response'
import { ApiHandlerResult } from '..'
import { API_URL } from '@/constants'
import { RecycleData } from '@/types/api/recycle'
import { RecycleStatus } from '@/types/Recycle'
import { ApiImage } from '@/types/Image'

interface GetRecyclesOptionsType {
  page?: number
  sort?: string
  type?: string
  pageSize?: number
  filter?: number[]
  category?: number
  excludeSold?: boolean
  userId?: number
  searchText?: string
  isActive?: boolean // works only for admin, true by default
}

interface StatusOptions {
  status: RecycleStatus
  reasonId?: number
}

export interface updateStatusOptionsType {
  statusOption: StatusOptions
  requestInit?: RequestInit
  Cookie?: string
}

export type ApiRecycleCategory = {
  id: number
  name: string
  created_at: string
  updated_at: string
  images_id: number
  slug: string
  image: ApiImage
  attribute: {
    id: number
    category_id: number
    name: string
    sort_order: number
    created_at: string
    updated_at: string
    options: {
      id: number
      attribute_id: number
      name: string
      sort_order: number
      created_at: string
      updated_at: string
    }
  }[]
}

export class RecyclesApi extends BaseApi {
  constructor(fetcher: Fetcher) {
    super(fetcher)
  }

  async categories(): ApiHandlerResult<ApiRecycleCategory[]> {
    try {
      const res = await this.fetcher.get(API_URL.RECYCLES.CATEGORIES)

      return await this.parseBodyOrThrow(res)
    } catch (err: any) {
      return [err, null]
    }
  }

  async search(
    params?: GetRecyclesOptionsType,
    options?: RequestInit
  ): ApiHandlerResult<PaginationResponse<RecycleData>> {
    try {
      const res = await this.fetcher.get(API_URL.RECYCLES.SEARCH, {
        params,
        ...options,
      })

      return await this.parseBodyOrThrow(res)
    } catch (err: any) {
      return [err, null]
    }
  }

  async updateStatusById(
    id: number,
    options: updateStatusOptionsType
  ): ApiHandlerResult<RecycleData> {
    try {
      const res = await this.fetcher.put(
        `${API_URL.RECYCLES.SEARCH}/${id}/status`,
        {
          body: JSON.stringify({
            status: options.statusOption.status,
            reasonId: options.statusOption.reasonId,
          }),
          ...options.requestInit,
        }
      )

      return await this.parseBodyOrThrow(res)
    } catch (err: any) {
      return [err, null]
    }
  }

  async getById(
    id: number,
    options?: RequestInit
  ): ApiHandlerResult<RecycleData> {
    try {
      // TODO: not sure why we need to pass view=true
      const res = await this.fetcher.get(
        `${API_URL.RECYCLES.SEARCH}/${id}?view=true`,
        options
      )

      return await this.parseBodyOrThrow(res)
    } catch (err: any) {
      return [err, null]
    }
  }
}
