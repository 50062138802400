'use client'

import Link from 'next/link'
import s from './main-nav.module.css'
import cx from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { Article } from '@/types/Article'
import { useHover } from 'usehooks-ts'
import { UiPlacement, UiPlacementType } from '@/types/api/ui-placement'
import { MenuItemsType } from '../../../../v2/organisms/navigation/navigation-index/navigation-index'
import { uppercaseEachWord } from '@/utils/uppercaseEachWord'
import { Supplier } from '@/types/Supplier'
import { navItemOnClickGTMEvent } from '@/utils/gtmEvents/navItemOnClickGTMEvent'
import { mapSupplierToCardProps } from '@/utils/mapSupplierToCardProps'
import { SliderWithNavigation } from '@/components/v3/molecules/custom-sliders/slider-with-navigation/slider-with-navigation'
import { mapArticleToCardProps } from '@/utils/mapArticleToCardProps'

function splitBusinessCategories(
  arr: MenuItemsType[0]['subcategories']
): UiPlacement[][] {
  const subcategories = []
  const uniqueRowTitles = [
    ...new Set(arr.map((item) => item.metadata?.rowTitle)),
  ]

  for (let i = 0; i < uniqueRowTitles.length; i++) {
    const row = arr.filter(
      (item) => item.metadata?.rowTitle === uniqueRowTitles[i]
    )
    subcategories.push(row)
  }

  return subcategories
}

export function splitSubcategories(
  arr: MenuItemsType[0]['subcategories']
): UiPlacement[][] {
  const subcategories = []

  if (arr[0]?.metadata?.rowTitle) {
    return splitBusinessCategories(arr)
  }

  const linksPerRow = 4

  for (let i = 0; i < arr.length; i += linksPerRow) {
    subcategories.push(arr.slice(i, i + linksPerRow))
  }

  return subcategories
}

export function getMainCategoryObject(
  item: MenuItemsType[0]['mainItem']
): UiPlacement['metadata'] {
  return item.type === UiPlacementType.METADATA
    ? item.metadata
    : item.article_categories
}

export function getSubcategoryObject(
  parentItem: UiPlacement['metadata'],
  item: MenuItemsType[0]['subcategories'][0]
): UiPlacement['metadata'] {
  const overrides = item?.metadata?.overrides
  const articleSubcategoryObj = overrides || item?.article_subcategories
  let slug = `/${parentItem?.slug}/${articleSubcategoryObj?.slug}`
  if (overrides?.slug) {
    slug = overrides.slug
  }

  return item?.type === UiPlacementType.BUSINESS_CATEGORY
    ? {
        slug: `/wedding-suppliers/${item.business_category?.slug}`,
        name: uppercaseEachWord(item.business_category?.mapping_name || ''),
        rowTitle: item.metadata?.rowTitle,
      }
    : { ...articleSubcategoryObj, slug }
}

type Props = {
  featuredArticles?: Article[]
  navbarSuppliers?: Supplier[]
  navbarVenues?: Supplier[]
  menuItems: MenuItemsType
}

export const MainNav = ({
  featuredArticles,
  navbarSuppliers,
  navbarVenues,
  menuItems,
}: Props) => {
  const [hoveredCategory, setHoveredCategory] = useState('')
  const containerRef = useRef(null)
  const itemHovered = useHover(containerRef)

  useEffect(() => {
    if (!itemHovered) {
      setHoveredCategory('')
    }
  }, [itemHovered])

  const handleCloseMenu = () => {
    setHoveredCategory('')
  }

  return (
    <nav className={s.mainNav} ref={containerRef}>
      <ul className={s.navList}>
        {menuItems.map((item) => {
          const mainCatObj: MenuItemsType[0]['mainItem']['metadata'] =
            item.mainItem.type === UiPlacementType.METADATA
              ? item.mainItem.metadata
              : item.mainItem.article_categories

          return (
            <li
              className={s.navItem}
              key={mainCatObj?.slug}
              onMouseEnter={() => {
                setHoveredCategory(mainCatObj?.slug || '')
              }}
              onClick={handleCloseMenu}
            >
              <Link
                href={`/${mainCatObj?.slug}`}
                onClick={() => navItemOnClickGTMEvent(mainCatObj?.name)}
                className={s.navLink}
              >
                {mainCatObj?.name}
              </Link>
            </li>
          )
        })}
      </ul>

      {menuItems.map((item) => {
        const mainCatObj = getMainCategoryObject(item.mainItem)
        const subcategories = splitSubcategories(item.subcategories)
        let sliderItems = mapArticleToCardProps(
          featuredArticles?.filter(
            (article) => article.category === mainCatObj?.slug
          )
        )

        if (item.mainItem.metadata?.showSuppliers) {
          sliderItems = mapSupplierToCardProps(navbarSuppliers)
        }

        if (item.mainItem.metadata?.showVenues) {
          sliderItems = mapSupplierToCardProps(navbarVenues)
        }

        const dropdownOpen =
          itemHovered &&
          hoveredCategory === mainCatObj?.slug &&
          !!subcategories.length

        const cardType = item.mainItem.metadata?.showSuppliers
          ? 'Supplier'
          : item.mainItem.metadata?.showVenues
            ? 'Venue'
            : 'Article'

        return (
          <div key={mainCatObj?.slug}>
            <div
              className={cx({ [s.navOverlay]: dropdownOpen })}
              onMouseEnter={handleCloseMenu}
            />
            <div
              className={cx(s.dropdown, {
                [s.dropdownOpen]: dropdownOpen,
                [s.dropdownWithSlider]: true,
              })}
            >
              <div className={s.dropdownWrapper}>
                <div className={s.dropdownNavWrapper}>
                  <Link
                    href={`/${mainCatObj?.slug}`}
                    className={s.subcategoryTitleWrapper}
                    onClick={() => {
                      handleCloseMenu()
                      navItemOnClickGTMEvent(mainCatObj?.name)
                    }}
                  >
                    <span className={s.subcategoryTitle}>
                      {mainCatObj?.name}
                    </span>
                    <span className={s.subcategoryTitleText}>View all</span>
                  </Link>
                  {subcategories.map((row, idx) => {
                    return (
                      <ul
                        key={`${mainCatObj?.slug}-subcategory-row-${idx}`}
                        className={s.navListSubcategory}
                      >
                        {!!row[0]?.metadata?.rowTitle && (
                          <li className={cx(s.navSubItem, s.navSubRowTitle)}>
                            {row[0]?.metadata?.rowTitle}
                          </li>
                        )}
                        {row.map((subcategory, i) => {
                          const subcategoryObj = getSubcategoryObject(
                            mainCatObj,
                            subcategory
                          )

                          return (
                            <li
                              className={s.navSubItem}
                              key={`${subcategoryObj?.slug}/${i}`}
                              onClick={handleCloseMenu}
                            >
                              <Link
                                href={subcategoryObj?.slug || ''}
                                className={s.navLinkSubcategory}
                                onClick={() =>
                                  navItemOnClickGTMEvent(subcategoryObj?.name)
                                }
                              >
                                {subcategoryObj?.name}
                              </Link>
                            </li>
                          )
                        })}
                      </ul>
                    )
                  })}
                </div>
                <div className={s.sliderContainer}>
                  <SliderWithNavigation
                    title={<span className={s.title}>Featured</span>}
                    onItemClick={handleCloseMenu}
                    items={sliderItems || []}
                    cardType={cardType}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </nav>
  )
}
