'use client'

import { Image } from '@/components/v2/atoms/image/image'
import s from './header.module.css'
import navButtonStyle from '../nav-button/nav-button.module.css'
import Link from 'next/link'
import { CSS_BREAKPOINTS_V2, PROD_URL, ROUTES } from '@/constants'
import { useUser } from '@/hooks/use-user'
import { MessagesIcon } from '@/components/svg/messages-icon/messages-icon'
import { UserIcon } from '@/components/svg/user-icon/user-icon'
import { useMessages } from '@/hooks/use-messages'
import { NavButton } from '../nav-button/nav-button'
import { NavSearch } from '../nav-search/nav-search'
import { BurgerIcon } from '@/components/svg/burger-icon/burger-icon'
import { VisibleOn } from '@/components/v3/atoms/visible-on/visible-on'
import { GoogleAdClient } from '@/components/v3/atoms/google-ad-client/google-ad-client'
import { usePathname } from 'next/navigation'
import { TOP_NAV_ADS } from '@/constants/ads-slots'
import { getAdObjectByKey } from '@/utils/getAdObjectByKey'

type Props = {
  onHamburgerIconClick: () => void
}

export const Header = ({ onHamburgerIconClick }: Props) => {
  const { isAdmin, isSupplier, isReader, user, logOut } = useUser()
  const { totalUnreadMessages } = useMessages()
  const pathname = usePathname()
  const isHomePage = pathname === '/'

  const messageIconLink = isSupplier
    ? '/supplier/dashboard/messages?tab=all-message'
    : '/customer/dashboard/messages?tab=all-message'

  const getProfileLink = () => {
    if (isAdmin) {
      return ROUTES.ADMIN.ROOT
    }

    if (isSupplier) {
      return ROUTES.SUPPLIER.SUMMARY
    }

    if (isReader) {
      return ROUTES.CUSTOMER.DASHBOARD
    }

    return ''
  }

  const profileLink = getProfileLink()
  const adObject = getAdObjectByKey(pathname, TOP_NAV_ADS)

  return (
    <>
      {adObject && (
        <GoogleAdClient
          mobileAd={{
            ...adObject.mobile,
            divProps: {
              style: {
                margin: '0 auto',
                width: `${adObject.mobile.size[0]}px`,
                height: `${adObject.mobile.size[1]}px`,
              },
            },
          }}
          desktopAd={{
            ...adObject.desktop,
            divProps: {
              style: {
                margin: '0 auto',
                width: `${adObject.desktop.size[0]}px`,
                height: `${adObject.desktop.size[1]}px`,
              },
            },
          }}
        />
      )}

      <div className={s.container}>
        <div className={s.innerContainer}>
          <div className={s.leftWrapper}>
            <VisibleOn desktop>
              <NavSearch />
            </VisibleOn>

            <VisibleOn mobile tablet>
              <button
                className={s.burger}
                onClick={onHamburgerIconClick}
                aria-label="Open menu"
              >
                <BurgerIcon />
              </button>
            </VisibleOn>
          </div>

          <div className={s.centerWrapper}>
            <Link href={ROUTES.HOME}>
              {isHomePage ? (
                <h1 className={s.homePageHeader}>
                  Rock My Wedding
                  <Image
                    mediaQuerySources={[
                      {
                        src: `${PROD_URL}/assets/images/logo/logo-app.png`,
                        media: `(max-width: ${CSS_BREAKPOINTS_V2.desktopS})`,
                        width: 160,
                        height: 50,
                      },
                      {
                        src: `${PROD_URL}/assets/images/logo/logo-app.png`,
                        media: `(min-width: ${CSS_BREAKPOINTS_V2.desktopS})`,
                        width: 180,
                        height: 60,
                      },
                    ]}
                    src={''}
                    alt="Rock My Wedding"
                    className={s.logo}
                    width={90}
                    height={30}
                    fit
                  />
                </h1>
              ) : (
                <Image
                  mediaQuerySources={[
                    {
                      src: `${PROD_URL}/assets/images/logo/logo-app.png`,
                      media: `(max-width: ${CSS_BREAKPOINTS_V2.desktopS})`,
                      width: 80,
                      height: 25,
                    },
                    {
                      src: `${PROD_URL}/assets/images/logo/logo-app.png`,
                      media: `(min-width: ${CSS_BREAKPOINTS_V2.desktopS})`,
                      width: 90,
                      height: 30,
                    },
                  ]}
                  src={''}
                  alt="Rock My Wedding"
                  width={90}
                  height={30}
                  fit
                />
              )}
            </Link>
          </div>

          <div className={s.rightWrapper}>
            {user ? (
              <div className={s.userInfo}>
                {!isAdmin && (
                  <NavButton href={messageIconLink}>
                    <MessagesIcon />{' '}
                    <VisibleOn tablet desktop>
                      Messages
                    </VisibleOn>{' '}
                    ({totalUnreadMessages} new)
                  </NavButton>
                )}
                <NavButton type="secondary" href={profileLink}>
                  <UserIcon
                    iconColor="#fff"
                    svgProps={{
                      'aria-label': 'User Profile',
                    }}
                  />{' '}
                  <VisibleOn tablet desktop>
                    {user?.first_name}
                  </VisibleOn>
                </NavButton>
                <VisibleOn desktop>
                  <NavButton
                    elementProps={{
                      onClick: logOut,
                      style: { paddingRight: 0 },
                    }}
                  >
                    Log out
                  </NavButton>
                </VisibleOn>
              </div>
            ) : (
              <div className={s.userInfo}>
                <NavButton href={ROUTES.SIGN_IN}>
                  <UserIcon /> Log in
                </NavButton>

                <VisibleOn desktop>
                  {/* using <a /> here because when using <NavButton /> it navigated to login page for some reason */}
                  <a className={navButtonStyle.button} href={ROUTES.SIGN_UP}>
                    Create Account
                  </a>
                </VisibleOn>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
