import { API_URL } from '@/constants'
import { ApiHandlerResult } from '..'
import { BaseApi } from '../base'
import { RequestInit } from 'next/dist/server/web/spec-extension/request'
import { Fetcher } from '@/api/fetcher'

export type ApiNumberOfGuestsOption = {
  id: number
  min: number
  max: number
  label: string
}

export type ApiIdealBudgetOption = {
  id: number
  min: number
  max: number
  label: string
}

export class CustomerEventsApi extends BaseApi {
  constructor(fetcher: Fetcher) {
    super(fetcher)
  }

  async getNumberOfGuestsOptionList(
    options?: RequestInit
  ): ApiHandlerResult<ApiNumberOfGuestsOption[]> {
    try {
      const res = await this.fetcher.get(
        API_URL.CUSTOMER_EVENTS.NUMBER_OF_GUESTS,
        {
          ...options,
        }
      )

      return await this.parseBodyOrThrow(res)
    } catch (err: any) {
      return [err, null]
    }
  }

  async getIdealBudgetOptions(
    options?: RequestInit
  ): ApiHandlerResult<ApiIdealBudgetOption[]> {
    try {
      const res = await this.fetcher.get(API_URL.CUSTOMER_EVENTS.IDEAL_BUDGET, {
        ...options,
      })

      return await this.parseBodyOrThrow(res)
    } catch (err: any) {
      return [err, null]
    }
  }
}
