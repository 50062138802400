import { Fetcher } from '@/api/fetcher'
import { ApiError } from '@/errors/apiError'
import { ErrorCode } from '@/errors/errorCode'

export class BaseApi {
  protected fetcher: Fetcher

  constructor(fetcher: Fetcher) {
    this.fetcher = fetcher
  }

  protected async rethrowResponseError(res: Response) {
    if (res.status >= 400) {
      throw await { status: res.status, body: await res.json() }
    }
  }

  protected async parseBodyOrThrow(res: Response): Promise<[null, any]> {
    let body = {}
    try {
      body = await res.json()
    } catch (err: any) {
      console.log(
        `Error parsing response body ${ErrorCode.e00121}`,
        err?.message
      )
    }

    if (res.status >= 400) {
      throw new ApiError(res.status, body)
    }

    return [null, body]
  }
}
