import { API_URL } from '@/constants'
import { ApiHandlerResult } from '..'
import { BaseApi } from '../base'
import { RequestInit } from 'next/dist/server/web/spec-extension/request'
import { Fetcher } from '@/api/fetcher'
import { Tag, TagSectionType } from '@/types/Tag'

export type GetTagsParams = {
  disabled_onboard?: boolean
  section?: TagSectionType
  category?: string
  ids?: number[]
  supplier_id?: number
}

export class TagsApi extends BaseApi {
  constructor(fetcher: Fetcher) {
    super(fetcher)
  }

  async getMany(
    params: GetTagsParams,
    options?: RequestInit
  ): ApiHandlerResult<Tag[]> {
    try {
      const res = await this.fetcher.get(API_URL.TAG.ROOT, {
        params,
        ...options,
      })

      return await this.parseBodyOrThrow(res)
    } catch (err: any) {
      return [err, null]
    }
  }
}
