import { ApiHandlerResult } from '..'
import { API_URL } from '@/constants'
import { BaseApi } from '../base'
import { Fetcher } from '@/api/fetcher'

type SitemapResponseEntity = {
  url: string
  lastModified: string
}

type SitemapIndexResponseEntity = {
  loc: string
  lastmod: string
}

export class SitemapsApi extends BaseApi {
  constructor(fetcher: Fetcher) {
    super(fetcher)
  }

  async getDynamicSitemaps(
    sitemapType: string
  ): ApiHandlerResult<SitemapResponseEntity[]> {
    try {
      const res = await this.fetcher.get(
        `${API_URL.METADATA.DYNAMIC_SITEMAPS}/${sitemapType}`
      )

      return await this.parseBodyOrThrow(res)
    } catch (error: any) {
      return [error, null]
    }
  }

  async getSitemapForArticleCategory(
    category: string
  ): ApiHandlerResult<SitemapResponseEntity[]> {
    try {
      const res = await this.fetcher.get(
        `${API_URL.METADATA.SITEMAP_ARTICLE_CATEGORY}/${category}`
      )

      return await this.parseBodyOrThrow(res)
    } catch (error: any) {
      return [error, null]
    }
  }

  async getSitemapIndex(): ApiHandlerResult<SitemapIndexResponseEntity[]> {
    try {
      const res = await this.fetcher.get(API_URL.METADATA.SITEMAP_INDEX)

      return await this.parseBodyOrThrow(res)
    } catch (error: any) {
      return [error, null]
    }
  }
}
