import { AdSlot } from '@/components/v3/atoms/google-ad-client/google-ad-client'

export const CATEGORY_PAGE_ADS: {
  [path: string]: {
    desktop: {
      middleAd: AdSlot
      bottomAd: AdSlot
    }
    mobile: {
      middleAd: AdSlot
      bottomAd: AdSlot
    }
  }
} = {
  '/beauty': {
    desktop: {
      middleAd: {
        adUnit: '/23279097297/beauty_halfpagebanner_1_300x600',
        size: [300, 600],
        divId: 'div-gpt-ad-1741695433676-0',
      },
      bottomAd: {
        adUnit: '/23279097297/beauty_halfpagebanner_2_300x600',
        size: [300, 600],
        divId: 'div-gpt-ad-1741695455514-0',
      },
    },
    mobile: {
      middleAd: {
        adUnit: '/23279097297/beauty_mediumrectangle_2_300x250',
        size: [300, 250],
        divId: 'div-gpt-ad-1741695504566-0',
      },
      bottomAd: {
        adUnit: '/23279097297/beauty_mediumrectangle_3_300x250',
        size: [300, 250],
        divId: 'div-gpt-ad-1741695524820-0',
      },
    },
  },
  '/competitions': {
    desktop: {
      middleAd: {
        adUnit: '/23279097297/competitions_halfpagebanner_1_300x600',
        size: [300, 600],
        divId: 'div-gpt-ad-1741695564913-0',
      },
      bottomAd: {
        adUnit: '/23279097297/competitions_halfpagebanner_2_300x600',
        size: [300, 600],
        divId: 'div-gpt-ad-1741695587048-0',
      },
    },
    mobile: {
      middleAd: {
        adUnit: '/23279097297/competitions_mediumrectangle_2_300x250',
        size: [300, 250],
        divId: 'div-gpt-ad-1741695635163-0',
      },
      bottomAd: {
        adUnit: '/23279097297/competitions_mediumrectangle_3_300x250',
        size: [300, 250],
        divId: 'div-gpt-ad-1741695673101-0',
      },
    },
  },
  '/engagement': {
    desktop: {
      middleAd: {
        adUnit: '/23279097297/engagement_halfpagebanner_1_300x600',
        size: [300, 600],
        divId: 'div-gpt-ad-1741695726767-0',
      },
      bottomAd: {
        adUnit: '/23279097297/engagement_halfpagebanner_2_300x600',
        size: [300, 600],
        divId: 'div-gpt-ad-1741695758434-0',
      },
    },
    mobile: {
      middleAd: {
        adUnit: '/23279097297/engagement_mediumrectangle_2_300x250',
        size: [300, 250],
        divId: 'div-gpt-ad-1741695801481-0',
      },
      bottomAd: {
        adUnit: '/23279097297/engagement_mediumrectangle_3_300x250',
        size: [300, 250],
        divId: 'div-gpt-ad-1741695823235-0',
      },
    },
  },
  '/fashion': {
    desktop: {
      middleAd: {
        adUnit: '/23279097297/fashion_halfpagebanner_1_300x600',
        size: [300, 600],
        divId: 'div-gpt-ad-1741695867110-0',
      },
      bottomAd: {
        adUnit: '/23279097297/fashion_halfpagebanner_2_300x600',
        size: [300, 600],
        divId: 'div-gpt-ad-1741695892523-0',
      },
    },
    mobile: {
      middleAd: {
        adUnit: '/23279097297/fashion_mediumrectangle_2_300x250',
        size: [300, 250],
        divId: 'div-gpt-ad-1741695943205-0',
      },
      bottomAd: {
        adUnit: '/23279097297/fashion_mediumrectangle_3_300x250',
        size: [300, 250],
        divId: 'div-gpt-ad-1741695981487-0',
      },
    },
  },
  '/gifts': {
    desktop: {
      middleAd: {
        adUnit: '/23279097297/gifts_halfpagebanner_1_300x600',
        size: [300, 600],
        divId: 'div-gpt-ad-1741696035125-0',
      },
      bottomAd: {
        adUnit: '/23279097297/gifts_halfpagebanner_2_300x600',
        size: [300, 600],
        divId: 'div-gpt-ad-1741696060011-0',
      },
    },
    mobile: {
      middleAd: {
        adUnit: '/23279097297/gifts_mediumrectangle_2_300x250',
        size: [300, 250],
        divId: 'div-gpt-ad-1741696103526-0',
      },
      bottomAd: {
        adUnit: '/23279097297/gifts_mediumrectangle_3_300x250',
        size: [300, 250],
        divId: 'div-gpt-ad-1741696130580-0',
      },
    },
  },
  '/hen': {
    desktop: {
      middleAd: {
        adUnit: '/23279097297/hen_halfpagebanner_1_300x600',
        size: [300, 600],
        divId: 'div-gpt-ad-1741696178155-0',
      },
      bottomAd: {
        adUnit: '/23279097297/hen_halfpagebanner_2_300x600',
        size: [300, 600],
        divId: 'div-gpt-ad-1741696200602-0',
      },
    },
    mobile: {
      middleAd: {
        adUnit: '/23279097297/hen_mediumrectangle_2_300x250',
        size: [300, 250],
        divId: 'div-gpt-ad-1741696260997-0',
      },
      bottomAd: {
        adUnit: '/23279097297/hen_mediumrectangle_3_300x250',
        size: [300, 250],
        divId: 'div-gpt-ad-1741696282668-0',
      },
    },
  },
  '/travel': {
    desktop: {
      middleAd: {
        adUnit: '/23279097297/travel_halfpagebanner_1_300x600',
        size: [300, 600],
        divId: 'div-gpt-ad-1741868081178-0',
      },
      bottomAd: {
        adUnit: '/23279097297/travel_halfpagebanner_2_300x600',
        size: [300, 600],
        divId: 'div-gpt-ad-1741868105829-0',
      },
    },
    mobile: {
      middleAd: {
        adUnit: '/23279097297/travel_mediumrectangle_2_300x250',
        size: [300, 250],
        divId: 'div-gpt-ad-1741868161301-0',
      },
      bottomAd: {
        adUnit: '/23279097297/travel_mediumrectangle_3_300x250',
        size: [300, 250],
        divId: 'div-gpt-ad-1741868182481-0',
      },
    },
  },
  '/wedding-inspiration': {
    desktop: {
      middleAd: {
        adUnit: '/23279097297/wedding-inspiration_halfpagebanner_1_300x600',
        size: [300, 600],
        divId: 'div-gpt-ad-1741695433676-0',
      },
      bottomAd: {
        adUnit: '/23279097297/wedding-inspiration_halfpagebanner_2_300x600',
        size: [300, 600],
        divId: 'div-gpt-ad-1741695455514-0',
      },
    },
    mobile: {
      middleAd: {
        adUnit: '/23279097297/wedding-inspiration_mediumrectangle_2_300x250',
        size: [300, 250],
        divId: 'div-gpt-ad-1741695504566-0',
      },
      bottomAd: {
        adUnit: '/23279097297/wedding-inspiration_mediumrectangle_3_300x250',
        size: [300, 250],
        divId: 'div-gpt-ad-1741695524820-0',
      },
    },
  },
  '/wedding-planning': {
    desktop: {
      middleAd: {
        adUnit: '/23279097297/wedding-planning_halfpagebanner_1_300x600',
        size: [300, 600],
        divId: 'div-gpt-ad-1741868384224-0',
      },
      bottomAd: {
        adUnit: '/23279097297/wedding-planning_halfpagebanner_2_300x600',
        size: [300, 600],
        divId: 'div-gpt-ad-1741868409211-0',
      },
    },
    mobile: {
      middleAd: {
        adUnit: '/23279097297/wedding-planning_mediumrectangle_2_300x250',
        size: [300, 250],
        divId: 'div-gpt-ad-1741868453939-0',
      },
      bottomAd: {
        adUnit: '/23279097297/wedding-planning_mediumrectangle_3_300x250',
        size: [300, 250],
        divId: 'div-gpt-ad-1741868472938-0',
      },
    },
  },
}

export const TOP_NAV_ADS: {
  [path: string]: {
    desktop: Omit<AdSlot, 'divProps'>
    mobile: Omit<AdSlot, 'divProps'>
  }
} = {
  '/beauty': {
    desktop: {
      adUnit: '/23279097297/beauty_billboard_1_970x250',
      size: [970, 250],
      divId: 'div-gpt-ad-1741695365693-0',
    },
    mobile: {
      adUnit: '/23279097297/beauty_mediumrectangle_1_300x250',
      size: [300, 250],
      divId: 'div-gpt-ad-1741695478103-0',
    },
  },
  '/competitions': {
    desktop: {
      adUnit: '/23279097297/competitions_billboard_1_970x250',
      size: [970, 250],
      divId: 'div-gpt-ad-1741695545530-0',
    },
    mobile: {
      adUnit: '/23279097297/competitions_mediumrectangle_1_300x250',
      size: [300, 250],
      divId: 'div-gpt-ad-1741695609736-0',
    },
  },
  '/engagement': {
    desktop: {
      adUnit: '/23279097297/engagement_billboard_1_970x250',
      size: [970, 250],
      divId: 'div-gpt-ad-1741695703204-0',
    },
    mobile: {
      adUnit: '/23279097297/engagement_mediumrectangle_1_300x250',
      size: [300, 250],
      divId: 'div-gpt-ad-1741695780342-0',
    },
  },
  '/fashion': {
    desktop: {
      adUnit: '/23279097297/fashion_billboard_1_970x250',
      size: [970, 250],
      divId: 'div-gpt-ad-1741695845719-0',
    },
    mobile: {
      adUnit: '/23279097297/fashion_mediumrectangle_1_300x250',
      size: [300, 250],
      divId: 'div-gpt-ad-1741695922091-0',
    },
  },
  '/gifts': {
    desktop: {
      adUnit: '/23279097297/gifts_billboard_1_970x250',
      size: [970, 250],
      divId: 'div-gpt-ad-1741696006156-0',
    },
    mobile: {
      adUnit: '/23279097297/gifts_mediumrectangle_1_300x250',
      size: [300, 250],
      divId: 'div-gpt-ad-1741696083379-0',
    },
  },
  '/hen': {
    desktop: {
      adUnit: '/23279097297/hen_billboard_1_970x250',
      size: [970, 250],
      divId: 'div-gpt-ad-1741696155216-0',
    },
    mobile: {
      adUnit: '/23279097297/hen_mediumrectangle_1_300x250',
      size: [300, 250],
      divId: 'div-gpt-ad-1741696223572-0',
    },
  },
  '/': {
    desktop: {
      adUnit: '/23279097297/homepage_billboard_1_970x250',
      size: [970, 250],
      divId: 'div-gpt-ad-1741696314636-0',
    },
    mobile: {
      adUnit: '/23279097297/homepage_mediumrectangle_1_300x250',
      size: [300, 250],
      divId: 'div-gpt-ad-1741867967071-0',
    },
  },
  '/travel': {
    desktop: {
      adUnit: '/23279097297/travel_billboard_1_970x250',
      size: [970, 250],
      divId: 'div-gpt-ad-1741868055400-0',
    },
    mobile: {
      adUnit: '/23279097297/travel_mediumrectangle_1_300x250',
      size: [300, 250],
      divId: 'div-gpt-ad-1741868126443-0',
    },
  },
  '/wedding-inspiration': {
    desktop: {
      adUnit: '/23279097297/wedding-inspiration_billboard_1_970x250',
      size: [970, 250],
      divId: 'div-gpt-ad-1741868210615-0',
    },
    mobile: {
      adUnit: '/23279097297/wedding-inspiration_mediumrectangle_1_300x250',
      size: [300, 250],
      divId: 'div-gpt-ad-1741868285769-0',
    },
  },
  '/wedding-planning': {
    desktop: {
      adUnit: '/23279097297/wedding-planning_billboard_1_970x250',
      size: [970, 250],
      divId: 'div-gpt-ad-1741868360707-0',
    },
    mobile: {
      adUnit: '/23279097297/wedding-planning_mediumrectangle_1_300x250',
      size: [300, 250],
      divId: 'div-gpt-ad-1741868433249-0',
    },
  },
}

type HomepageAdSlots = {
  desktop: Omit<AdSlot, 'divProps'>
  mobile: Omit<AdSlot, 'divProps'>
}

export const HOMEPAGE_ADS: {
  billboard: HomepageAdSlots
  banner: HomepageAdSlots
} = {
  billboard: {
    desktop: {
      adUnit: '/23279097297/homepage_billboard_2_970x250',
      size: [970, 250],
      divId: 'div-gpt-ad-1741867894535-0',
    },
    mobile: {
      adUnit: '/23279097297/homepage_mediumrectangle_2_300x250',
      size: [300, 250],
      divId: 'div-gpt-ad-1741868002527-0',
    },
  },
  banner: {
    desktop: {
      adUnit: '/23279097297/homepage_halfpagebanner_300x600',
      size: [300, 600],
      divId: 'div-gpt-ad-1741867940886-0',
    },
    mobile: {
      adUnit: '/23279097297/homepage_mediumrectangle_3_300x250',
      size: [300, 250],
      divId: 'div-gpt-ad-1741868026275-0',
    },
  },
}
