import s from './visible-on.module.css'
import cx from 'classnames'

type Props = {
  children: React.ReactNode
  className?: string
  mobile?: boolean
  tablet?: boolean
  desktop?: boolean
}

export const VisibleOn = ({
  children,
  className,
  mobile,
  tablet,
  desktop,
}: Props) => {
  return (
    <div
      className={cx(className, {
        [s.visibleOnMobile]: mobile,
        [s.visibleOnTablet]: tablet,
        [s.visibleOnDesktop]: desktop,
      })}
    >
      {children}
    </div>
  )
}
