'use client'

import { VisibleOn } from '@/components/v3/atoms/visible-on/visible-on'
import React, { useEffect, useState } from 'react'
import { usePathname } from 'next/navigation'
import cx from 'classnames'
import s from './google-ad-client.module.css'

export type AdSlot = {
  adUnit: string
  size: [number, number]
  divId: string
  divProps?: React.HTMLAttributes<HTMLDivElement>
}

export type Props = {
  mobileAd: AdSlot
  desktopAd: AdSlot
  fallback?: React.ReactNode
  onAdsLoaded?: () => void
}

export const GoogleAdClient = ({
  mobileAd,
  desktopAd,
  fallback,
  onAdsLoaded,
}: Props) => {
  const pathname = usePathname()
  const [isMobileSlotRender, setMobileIsSlotRender] = useState(false)
  const [isDesktopSlotRender, setDesktopIsSlotRender] = useState(false)

  useEffect(() => {
    const getExistingSlot = (divId: string) => {
      return window.googletag
        .pubads()
        .getSlots()
        .find((slot) => slot.getSlotElementId() === divId)
    }

    const defineAd = (adInfo: AdSlot, screenType: 'mobile' | 'desktop') => {
      const existingSlot = getExistingSlot(adInfo.divId)

      if (existingSlot) {
        window.googletag.pubads().refresh([existingSlot])

        if (!existingSlot.getResponseInformation()) {
          return
        }

        switch (screenType) {
          case 'mobile':
            setMobileIsSlotRender(true)
          case 'desktop':
            setDesktopIsSlotRender(true)
          default:
            onAdsLoaded?.()
        }

        return
      }

      const adSlot = window.googletag
        ?.defineSlot(adInfo.adUnit, adInfo.size, adInfo.divId)
        ?.addService(window.googletag.pubads())

      if (adSlot) {
        window.googletag.pubads().collapseEmptyDivs()
        window.googletag.pubads().enableSingleRequest()
        window.googletag.enableServices()

        window.googletag
          .pubads()
          .addEventListener('slotRenderEnded', (event) => {
            if (event.slot === adSlot) {
              const adLoaded = !event.isEmpty
              adLoaded && onAdsLoaded?.()
              screenType === 'mobile'
                ? setMobileIsSlotRender(adLoaded)
                : setDesktopIsSlotRender(adLoaded)
            }
          })

        window.googletag.display(adSlot)
      }
    }

    const setupAds = () => {
      window.googletag = window.googletag || { cmd: [] }
      window.googletag?.cmd.push(() => {
        defineAd(mobileAd, 'mobile')
        defineAd(desktopAd, 'desktop')
      })
    }

    setupAds()
  }, [pathname])

  return (
    <>
      <VisibleOn mobile tablet>
        <div className={cx({ [s.hide]: !isMobileSlotRender })}>
          <div id={mobileAd.divId} {...mobileAd.divProps} />
        </div>
        <div className={cx({ [s.hide]: isMobileSlotRender })}>
          {fallback || null}
        </div>
      </VisibleOn>

      <VisibleOn desktop>
        <div className={cx({ [s.hide]: !isDesktopSlotRender })}>
          <div id={desktopAd.divId} {...desktopAd.divProps} />
        </div>
        <div className={cx({ [s.hide]: isDesktopSlotRender })}>
          {fallback || null}
        </div>
      </VisibleOn>
    </>
  )
}
