import { CardItem } from '@/components/v3/molecules/custom-sliders/slider-with-navigation/slider-with-navigation'
import { Supplier } from '@/types/Supplier'

export function mapSupplierToCardProps(
  suppliers?: Supplier[]
): CardItem[] | undefined {
  return suppliers?.map((supplier) => ({
    id: supplier.id,
    title: supplier.listing_name,
    path: supplier.slug,
    date: '',
    category: supplier.business_category.name,
    locations: supplier.locations,
    imageUrl: supplier.feature_images!.url!,
    imageAlt: supplier.feature_images!.description!,
  }))
}
